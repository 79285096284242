import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledSliderBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing(-11)};
  row-gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  z-index: 1;

  ${mq.medium`
   flex-direction: row;
   column-gap: ${({ theme }) => theme.spacing(2)};
  `}

  ${mq.large`
    justify-content: flex-start;
    margin: ${({ theme }) => theme.spacing(-4, 'auto', 0)};
  `}

  ${mq.xsLarge`
    max-width: 772px;
    margin: ${({ theme }) => theme.spacing(-8, 0, 0, 'auto')};
    padding: ${({ theme }) => theme.spacing(0, 4, 0, 0)};
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${mq.xsLarge`
    flex-direction: row; 
  `}
`;
