import React, { FC } from 'react';
import Image from '@next/image';

import fromCMS from 'utils/fromCMS';

import VideoPlayer from 'components/atoms/VideoPlayer/VideoPlayer';

import { SlideProps } from '../MainSlider';
import { StyledCarouselVideoItem, StyledCarouselItem, StyledSingleImageWrapper } from './StaticSlider.styles';

export type StaticSliderProps = SlideProps;

export const StaticSlider: FC<SlideProps> = ({ image, videoUrl, type }) =>
  type === 'video' ? (
    <StyledCarouselVideoItem>
      <VideoPlayer src={fromCMS(videoUrl)} autoPlay muted loop />
    </StyledCarouselVideoItem>
  ) : (
    <StyledCarouselItem>
      <StyledSingleImageWrapper>
        <Image src={fromCMS(image.file.url)} alt={image.alt} layout="fill" objectFit="cover" hasFade={false} />
      </StyledSingleImageWrapper>
    </StyledCarouselItem>
  );
