import styled from 'styled-components';
import { motion } from 'framer-motion';

import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  position: relative;

  ${mq.large`
    display: flex;
    align-items: center;
  `}

  ${mq.xsLarge`
    margin: 0 auto;
    width: 100%;
  `}
`;

export const StyledBgImage = styled.div`
  width: 758px;
  height: 746px;
  position: absolute;
  top: -140px;
  left: -230px;
  opacity: 0.4;

  ${mq.small`
    left: 0;
  `}
`;

export const StyledTextColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledButtonContainer = styled.div`
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${mq.large`
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 16px;
    width: 100%;
    height: auto;
  `}
`;

export const StyledPhotoColumn = styled(motion.div)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const StyledAnimatedImageWrapper = styled(motion.div)`
  width: 100%;
`;

export const StyledTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq.xsLarge`
    margin: ${({ theme }) => theme.spacing(5, 0, 0, 0)};
    max-width: ${({ theme }) => theme.sizes.container.xs};
  `}
`;

export const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.spacing(9)};

  ${mq.xsLarge`
    padding-top: ${({ theme }) => theme.spacing(1)};
  `}
`;
