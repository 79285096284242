import styled from 'styled-components';

export const StyledCarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
  user-select: none;
`;

export const StyledCarouselVideoItem = styled(StyledCarouselItem)`
  pointer-events: auto;
  overflow: hidden;
`;

export const StyledSingleImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
