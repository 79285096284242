import styled, { css } from 'styled-components';

import { VideoPlayerProps } from './VideoPlayer';

export const StyledVideo = styled.video<{
  hasHiddenOverflow: VideoPlayerProps['hasHiddenOverflow'];
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${({ hasHiddenOverflow }) =>
    hasHiddenOverflow &&
    css`
      overflow: hidden;
    `};
`;
