import React, { FC, useRef } from 'react';

import { StyledVideo } from './VideoPlayer.styles';

export type VideoPlayerProps = {
  src: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  controls?: boolean;
  hasHiddenOverflow?: boolean;
};

const VideoPlayer: FC<VideoPlayerProps> = ({
  src,
  autoPlay = false,
  muted = false,
  loop = false,
  controls = false,
  hasHiddenOverflow = false
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const toggleVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  return (
    <StyledVideo
      ref={videoRef}
      src={src}
      autoPlay={autoPlay}
      muted={muted}
      loop={loop}
      controls={controls}
      hasHiddenOverflow={hasHiddenOverflow}
      controlsList="nodownload"
      onClick={controls ? undefined : toggleVideo}
    />
  );
};

export default VideoPlayer;
