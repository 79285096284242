import React, { FC } from 'react';

import { Shape } from 'components/atoms/Shape/Shape';
import Hexagon from '../../atoms/Geometric/Hexagon/Hexagon';

type GeoShapesProps = {
  isHomePage?: boolean;
};

const GeoShapes: FC<GeoShapesProps> = ({ isHomePage = false }) => (
  <>
    {isHomePage && (
      <Hexagon width="366px" height="320px" top="10px" left="-100px" variant="mediumBlue" zIndex={2} rotate="348deg" />
    )}
    <Shape
      src="/shapes/main_slider_small.png"
      width={8}
      height={8}
      position="absolute"
      left="-100px"
      top="220px"
      zIndex={2}
    />
    <Shape
      src="/shapes/main_slider_bigger.png"
      width={18}
      height={18}
      position="absolute"
      left="-200px"
      top="400px"
      zIndex={2}
    />
  </>
);

export default GeoShapes;
