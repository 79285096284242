import React, { FC, ReactNode } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import { scrollToSection } from 'utils/scroll';
import UploadedImg from 'utils/types/UploadedImg';
import useMedia from 'hooks/useMedia';

import ContentBox, { ColumnColors } from 'components/ContentBox/ContentBox';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { FuncButton } from 'components/atoms/Button/FuncButton/FuncButton';
import { GenericProps } from 'components/atoms/Button/GenericButton';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';

import { StaticSlider } from './StaticSlider/StaticSlider';
import GeoShapes from './GeoShapes';

import { StyledTitleBox, StyledSliderBox, StyledTitleWrapper, StyledDescriptionWrapper } from './MainSlider.styles';

export type SlideProps = {
  id: number;
  type: string;
  image?: UploadedImg;
  videoUrl?: string;
};

export type MainSliderProps = {
  title: MDXRemoteSerializeResult;
  subtitle?: MDXRemoteSerializeResult;
  description: MDXRemoteSerializeResult;
  button: {
    textOnButton: string;
    href: string;
    variant: GenericProps['variant'];
  };
  mobileBackground: UploadedImg;
  singleSlides: Array<SlideProps>;
  boardTheme?: 'rectangle' | 'cropped';
  boardColor?: 'dark' | 'light';
  boardSize?: 'sm' | 'xl';
  customContent?: ReactNode;
  isHomePage?: boolean;
};

// @deprecated: Please use components/ContentBox instead
export const MainSlider: FC<MainSliderProps> = ({
  title,
  subtitle,
  description,
  button,
  singleSlides,
  boardTheme = 'rectangle',
  boardColor = 'light',
  boardSize = 'xl',
  mobileBackground,
  customContent = null,
  isHomePage = false
}) => {
  const buttonVariant = button.variant?.replace('_', '-') as GenericProps['variant'];
  const color = boardColor as ColumnColors;
  const { isMobile } = useMedia();

  return (
    <ContentBox
      bigShapes={!isHomePage}
      mobileBackground={mobileBackground || (singleSlides.length > 0 && singleSlides[0].image)}
      crop={boardTheme === 'cropped' ? 'rightToLeft' : undefined}
      geoShapes={<GeoShapes isHomePage={isHomePage} />}
      leftColumnColor={color}
      rightColumnColor={color}
      leftColumnContent={
        <StyledTitleBox boardTheme={boardTheme}>
          <StyledTitleWrapper variant={boardSize}>
            <Typography color="secondary" variant="h6" weight="800">
              <Marked>{description}</Marked>
            </Typography>
            <StyledDescriptionWrapper>
              <Typography variant="h3" renderAs="h1" weight="400" color="secondary">
                <Marked>{title}</Marked>
              </Typography>
              <br />
              {isHomePage && (
                <Typography variant="h1" renderAs="h1" color="liki-green">
                  <Marked>{subtitle}</Marked>
                </Typography>
              )}
            </StyledDescriptionWrapper>
            {customContent}
            {button?.href.startsWith('#') ? (
              <FuncButton variant={buttonVariant} onClick={() => scrollToSection(button.href.substring(1))}>
                {button.textOnButton}
              </FuncButton>
            ) : (
              <LinkedButton variant={buttonVariant} href={button.href}>
                {button.textOnButton}
              </LinkedButton>
            )}
          </StyledTitleWrapper>
        </StyledTitleBox>
      }
      rightColumnContent={
        !isMobile ? (
          <StyledSliderBox bigShapes variant={boardTheme}>
            <StaticSlider {...singleSlides[0]} />
          </StyledSliderBox>
        ) : null
      }
    />
  );
};
