import React, { FC, ReactNode } from 'react';
import Image from '@next/image';
import { useInView } from 'react-intersection-observer';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import Container from 'components/atoms/Container/Container';
import Typography, { TypographyProps } from 'components/atoms/Typography/Typography';
import ClutchWidget from 'components/atoms/ClutchWidget/ClutchWidget';
import { ClutchProps } from 'utils/types/Clutch';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { Marked } from 'components/Marked/Marked';

import { revealLeft, revealRight } from 'theme/animations';
import fromCMS from 'utils/fromCMS';
import useMedia from 'hooks/useMedia';

import {
  StyledWrapper,
  StyledTextBox,
  StyledTextWrapper,
  StyledImageBox,
  StyledImageWrapper,
  StyledAnimatedImageWrapper,
  StyledHoverBox,
  StyledBackShapes,
  StyledContainer,
  StyledBackgroundImageWrapper
} from './ImageLeftSection.styles';

export type ImageLeftSectionProps = {
  bgImage?: ReactNode;
  buttonAlignment?: AlignSetting;
  title: MDXRemoteSerializeResult;
  image: {
    src: string;
    alt: string;
  };
  button: { name: string; path: string };
  grayShape?: ReactNode;
  shapes?: ReactNode;
  backShapes?: ReactNode;
  reverseOrder?: boolean;
  hideImageOnMobile?: boolean;
  width?: string;
  isRelative?: boolean;
  marginTop?: string;
  paddingBottom?: string;
  imageTop?: string;
  overflowHidden?: boolean;
  threshold?: number;
  clutch?: ClutchProps;
  flexDisplayed?: boolean;
  justifyContent?: string;
  fontSize?: TypographyProps['variant'];
};

export const ImageLeftSection: FC<ImageLeftSectionProps> = ({
  bgImage,
  title,
  image,
  button,
  grayShape,
  shapes,
  backShapes,
  justifyContent,
  flexDisplayed = false,
  reverseOrder = false,
  hideImageOnMobile = false,
  buttonAlignment = 'start',
  width = '100%',
  isRelative = true,
  marginTop = '72px',
  imageTop = '0',
  overflowHidden,
  paddingBottom = '0',
  threshold = 0.5,
  clutch,
  fontSize
}) => {
  const { isMobile } = useMedia();
  const shouldHideImage = (isMobile && hideImageOnMobile) || !isMobile;

  const { ref, inView } = useInView({ triggerOnce: true, threshold: threshold });

  return (
    <StyledContainer ref={ref} overflowHidden={overflowHidden}>
      <Container>
        <StyledWrapper flexDisplayed={flexDisplayed} marginTop={marginTop} paddingBottom={paddingBottom}>
          {shouldHideImage && (
            <StyledImageBox reverseOrder={reverseOrder}>
              <StyledImageWrapper>
                {bgImage && <StyledBackgroundImageWrapper>{bgImage}</StyledBackgroundImageWrapper>}
                <StyledAnimatedImageWrapper
                  imageTop={imageTop}
                  animate={inView ? 'visible' : 'hidden'}
                  variants={reverseOrder ? revealRight : revealLeft}
                  width={width}
                  isRelative={isRelative}
                >
                  <Image
                    src={fromCMS(image.src)}
                    alt={image.alt}
                    width={750}
                    height={567}
                    objectFit="scale-down"
                    quality={100}
                    unoptimized
                    isDisplayFlex
                    justifyContent={justifyContent}
                  />
                </StyledAnimatedImageWrapper>
                {shapes && <StyledHoverBox>{shapes}</StyledHoverBox>}
              </StyledImageWrapper>
            </StyledImageBox>
          )}
          <StyledTextBox buttonAlignment={buttonAlignment} reverseOrder={reverseOrder}>
            {clutch && <ClutchWidget {...clutch} />}
            <StyledTextWrapper>
              <Typography variant={fontSize}>
                <Marked>{title}</Marked>
              </Typography>
            </StyledTextWrapper>
            <LinkedButton href={button.path} width="100%">
              {button.name}
            </LinkedButton>
          </StyledTextBox>
          {backShapes && <StyledBackShapes>{backShapes}</StyledBackShapes>}
          {grayShape && <>{grayShape}</>}
        </StyledWrapper>
      </Container>
    </StyledContainer>
  );
};
