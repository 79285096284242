import React, { FC } from 'react';
import Image from '@next/image';
import { useInView } from 'react-intersection-observer';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';
import { GenericProps } from 'components/atoms/Button/GenericButton';

import useMedia from 'hooks/useMedia';
import { revealRight } from 'theme/animations';
import fromCMS from 'utils/fromCMS';

import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import {
  StyledContainer,
  StyledTextColumn,
  StyledPhotoColumn,
  StyledAnimatedImageWrapper,
  StyledButtonContainer,
  StyledTextWrapper,
  StyledWrapper,
  StyledTitleWrapper,
  StyledBgImage
} from './ImageRightSection.styles';

export type ImageRightSectionProps = {
  title: MDXRemoteSerializeResult;
  description: MDXRemoteSerializeResult;
  buttons: Array<{
    textOnButton: string;
    href: string;
    variant: GenericProps['variant'];
  }>;
  image: {
    src: string;
    alt: string;
  };
};

export const ImageRightSection: FC<ImageRightSectionProps> = ({ title, description, buttons, image }) => {
  const { isMobile, isSmallMobile } = useMedia();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <StyledWrapper>
      {isMobile && (
        <StyledBgImage>
          <Image
            width="758px"
            height="746px"
            src="/img/mobile-phones.png"
            alt={image.alt}
            objectFit="cover"
            quality={100}
          />
        </StyledBgImage>
      )}
      <Container>
        <StyledContainer>
          {!isMobile && <Hexagon width="700px" top="-410px" left="-460px" variant="backgroundLight" zIndex="-1" />}
          <StyledTextColumn>
            <StyledTextWrapper>
              <StyledTitleWrapper>
                <Typography renderAs="h2" variant="h3" weight="700">
                  <div style={{ lineHeight: '48px' }}>
                    <Marked>{title}</Marked>
                  </div>
                </Typography>
              </StyledTitleWrapper>
              <Typography variant="body2">
                <Marked>{description}</Marked>
              </Typography>
              <StyledButtonContainer>
                {buttons.map((button) => (
                  <LinkedButton
                    key={button.href}
                    variant={button.variant}
                    href={button.href}
                    width={isSmallMobile ? '100%' : undefined}
                  >
                    {button.textOnButton}
                  </LinkedButton>
                ))}
              </StyledButtonContainer>
            </StyledTextWrapper>
          </StyledTextColumn>
          <StyledPhotoColumn ref={ref}>
            <div>
              {!isMobile && (
                <StyledAnimatedImageWrapper animate={inView ? 'visible' : 'hidden'} variants={revealRight}>
                  <div style={{ width: 758, height: 777, position: 'relative' }}>
                    <Image
                      layout="fill"
                      src={fromCMS(image.src)}
                      alt={image.alt}
                      objectFit="scale-down"
                      quality={100}
                    />
                  </div>
                </StyledAnimatedImageWrapper>
              )}
            </div>
          </StyledPhotoColumn>
        </StyledContainer>
      </Container>
    </StyledWrapper>
  );
};
